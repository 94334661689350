import React, { useState } from 'react'
import { RiMenu3Line, RiCloseLine } from 'react-icons/ri';
import logo from '../../assests/logo.svg'
import './navbar.css'

const Menu = () => (
  <>
    <p><a href='#home'>Home</a></p>
    <p><a href='#Sec1'>Sec1</a></p>
    <p><a href='#Sec2'>Sec2</a></p>
    <p><a href='#Sec3'>Sec3</a></p>
    <p><a href='#Sec4'>Sec4</a></p>
  </>
)


// BEM -> Block Element Modifiers
const Navbar = () => {
  const [toggleMenu, setToggleMenu] = useState(false);


  return (
    <div className='my__navbar'>
      <div className='my__navbar-links'>
        <div className='my__navbar-links_logo'>
          <img src={logo} alt="logo" />
        </div>
        <div className='my__navbar-links_container'>
          <Menu />
        </div>
      </div>
      <div className='my__navbar-sign'>
        <p>Sign in</p>
        <button type='button'>Sign up</button>
      </div>
      <div className='my__navbar-menu'>
        {toggleMenu
          ? <RiCloseLine color='#fff' size={27} onClick={() => setToggleMenu(false)} />
          : <RiMenu3Line color='#fff' size={27} onClick={() => setToggleMenu(true)} />
        }
        {toggleMenu && (
          <div className='my__navbar-menu_container scale-up-center' >
            <div className='my__navbar-menu-container-links'>
              <Menu />
              <div className='my__navbar-menu_container-links-sign'>
                <p>Sign in</p>
                <button type='button'>Sign up</button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>

  )
}

export default Navbar