import React from 'react'
import './header.css'
import people from '../../assests/people.png'
import ai from '../../assests/ai.png'

const Header = () => {
  return (
    <div className='my__header section__padding' id="home">
      <div className='my__header-content'>
        <h1 className='gradient__text'>Wow so amazing build a SITE easy peasy</h1>
        <p>Start building amazing site with amazing maze doing whatevery
          you want with a click of a button wow just put your email downbelow
          and start making react site in no time, just 99999.99$ a month and your soul
        </p>
        <div className='my__header-content__input'>
          <input type='email' placeholder='Your Email' />
          <button type='button' >Get Started</button>
        </div>
        <div className='my__header-contect__people'>
          <img src={people} alt="people" />
          <p>this is all the people that know nothing</p>
        </div>

      </div>
      <div className='my__header-image'>
        <img src={ai} alt='ai' />
      </div>
    </div>
  )
}

export default Header