import React from 'react'

import { Body, Footer, Header } from './containers';
import { Article, Navbar, Brand } from './componenets';
import './App.css';

const App = () => {
  return (


    <div className='App'>
    
       <div className='gradient__bg'>
        <Navbar/>
        <Header/>
       </div>
       <Brand/>
       <Article/>
       <Body/>
       <Footer/>
    </div>
  )
}

export default App
